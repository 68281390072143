<template>
  <!-- 用户管理 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        :loading="tabLoading" :stripe="true" @selection-change="handleSelectionChange"
        :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
    <!-- 角色弹框 -->
    <el-dialog title="新增角色" :visible.sync="dialogFormVisible" width="30%" @close="handleClose">
      <el-form :model="roleForm" ref="roleForm" :rules="rules">
        <el-form-item label="角色名称" prop="role" :rules="{required: true, message: '角色名称不能为空', trigger: 'blur'}"
          label-width="120px">
          <el-input type="text" v-model="roleForm.role" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description" :rules="{required: true, message: '描述不能为空', trigger: 'blur'}"
          label-width="120px">
          <el-input type="text" v-model="roleForm.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="butLoading" @click="AddEditRole">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 权限弹框 -->
    <el-dialog title="配置权限" :visible.sync="dialogFormJurisdiction" width="30%">
      <el-checkbox v-model="allChecked" @change="handleCheckAllChange" class="pv-30">全选</el-checkbox>
      <div class="mv-40 h-250 overflow-scroll-y">
        <el-tree :data="treeData" :props="defaultProps" show-checkbox node-key="id" ref="tree" default-expand-all
          :default-checked-keys="defaultCheckedKeys" highlight-current :expand-on-click-node="false" class="mt-20"
          @check="currentChecked">
          <div class="flex1" slot-scope="{ node, data }">
            <div>{{ node.label }}</div>
            <!-- <div class="flex flex-ac mt-10">
              <div v-for="(item,index) in data['buttons']" :key="index" class="mr-10" :class="item.id?'fc-3b7cff':''"
                @click="getRole(item)">
                {{item.name}}
              </div>
            </div> -->
          </div>
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormJurisdiction = false">取 消</el-button>
        <el-button type="primary" :loading="butLoading" @click="addSysRolePerm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { nextTick } from 'vue'
export default {
  name: 'user',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '角色', prop: 'role', align: 'left', 'show-overflow-tooltip': true },
        { label: '角色描述', prop: 'description', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '300' },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '160',
          buttons: [
            { type: 'text', text: '删除', fn: this.deleteList, },
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '权限', fn: this.jurisdiction, }
          ],
        },
      ],
      tableData: [],
      roleData: '',//选择角色
      //弹框
      dialogFormVisible: false,
      dialogFormJurisdiction: false,
      roleForm: {
        id: '',
        role: '',
        description: '',
      },
      //角色校验
      rules: {
        role: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      },
      //权限列表
      allChecked: false,//全选
      treeData: [],
      defaultCheckedKeys: [],//默认选中权限
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      rolePower: [], //权限
    }
  },
  mounted () {
    console.log(window.Glob)
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.getTableList()
    },
    //新增列表
    addList () {
      this.roleForm = {
        id: '',
        role: '',
        description: '',
      }
      this.dialogFormVisible = true
    },
    //导出
    exportExcel () { },
    //跳转详情
    editList (row) {
      console.log('跳转详情', row)
      this.roleForm = {
        id: row.id,
        role: row.role,
        description: row.description,
      }
      this.dialogFormVisible = true
    },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/role/findRoleList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          role: this.retrieveForm.searchValue,
          type: 'page'
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //新增角色
    AddEditRole () {
      this.$refs['roleForm'].validate((valid) => {
        if (valid) {
          if (this.roleForm.id) {
            this.$http.post('/role/editRole', this.roleForm).then(({ data: result }) => {
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.getTableList()
            })
          } else {
            this.$http.post('/role/addRole', this.roleForm).then(({ data: result }) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.getTableList()
            })
          }
          this.dialogFormVisible = false
        }
      })
    },
    //删除角色
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/role/deleRole', {
          id: row.id
        }).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        })
      }).catch(_ => { })
    },
    // 设置权限
    jurisdiction (row) {
      this.roleData = row
      const each = (list) => {
        list.forEach(element => {
          if (element.resourceType != "button") {
            if (element.buttons) {
              element.children = element.children.concat(element.buttons)
            }
            if (element.children.length > 0) {
              each(element.children)
            }
          }
        })
      }
      this.$http.get('/permission/findPermissionList').then(({ data: res }) => {
        if (res.code == 1) {
          let that = this
          let treeData = res.data
          each(treeData)
          that.treeData = treeData
          that.dialogFormJurisdiction = true

          that.$nextTick(() => {
            // 默认选中
            row.permissionList.forEach(function (ele) {
              var node = that.$refs.tree.getNode(ele.id)
              if (node.isLeaf) {
                that.$refs.tree.setChecked(node, true)
              }
            })
          })
        }
      })
    },
    //全选
    handleCheckAllChange (val) {
      if (this.allChecked) {
        let ids = []
        const each = (list) => {
          list.forEach(element => {
            ids.push(element.id)
            if (element.children && element.children.length > 0) {
              each(element.children)
            }
          })
        }
        this.$refs.tree.setCheckedNodes(this.treeData)
        each(this.treeData)
        this.rolePower = ids
      } else {
        this.$refs.tree.setCheckedKeys([])
        this.rolePower = []
      }
    },
    //菜单权限
    currentChecked (nodeObj, SelectedObj) {
      // console.log(nodeObj, '选中')
      // console.log(SelectedObj,'ttt')
      // console.log(SelectedObj.checkedKeys,'pppp')   // 这是选中的节点的key数组 
      // 取消子节点下其中一个复选框不会返回父节点id
      // console.log(SelectedObj.checkedNodes, 'oooo')  // 这是选中的节点数组 
      let id = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys())
      //勾选与取消都会返回父节点id
      // this.check = SelectedObj.checkedKeys
      // this.sysRoleUpdateCheck(id)
      console.log(id, 'this.$refs.tree.getCheckedKeys()')
      this.rolePower = id
    },
    //点击按钮
    // getRole (even) {
    //   this.rolePower.push(even.id)
    // },
    //分配权限
    addSysRolePerm () {
      this.butLoading = true
      this.$http.post('/role/addSysRolePermisson', {
        perminssonId: this.rolePower.join(','),
        roleId: this.roleData.id,
      }).then(({ data: res }) => {
        this.$message({
          message: '分配成功',
          type: 'success'
        })
        this.butLoading = false
        this.getTableList()
        this.dialogFormJurisdiction = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    //选择表格
    handleSelectionChange () { },
    //关闭弹框
    handleClose () {
      Object.assign(this.roleForm, this.$options.data().roleForm)
      this.$nextTick(() => {
        this.$refs.roleForm.clearValidate() //清除校验
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-tree-node__content {
  // height: 60px !important;
  padding: 5px;
  height: auto !important;
  align-items: start;
}
</style>